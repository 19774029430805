import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./homepage.scss"
import styled from "styled-components"
import Badge from "../common/Badge"

const VideoBlock = () => {
  const data = useStaticQuery(
    graphql`
      query GetPromotedVideo {
        drupal {
          nodeQuery(
            filter: {
              conditions: [
                { field: "status", value: "1", operator: EQUAL }
                { field: "type", value: "video", operator: EQUAL }
              ]
            }
            sort: { field: "field_release_date", direction: DESC }
            offset: 0
            limit: 1
          ) {
            count
            entities {
              entityId
              entityLabel
              entityUrl {
                path
              }
              ... on Default_NodeVideo {
                embedCode: fieldEmbedCode
                releaseDate: fieldReleaseDate {
                  value
                  date
                }
                promote
              }
            }
          }
        }
      }
    `
  )

  if (data?.drupal?.nodeQuery?.entities?.length) {
    const entities = data?.drupal?.nodeQuery?.entities || []
    return (
      <Container className="container text-center py-section ">
        <>
          <Badge color="#ffffff">Video</Badge>
          <h2>{entities[0].entityLabel}</h2>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: entities[0].embedCode }}
              className="iframe-container"
            />
          </div>
        </>
        <p style={{ position: "relative", zIndex: 1 }}>
          <Link to={"/videos"} className="read-more white">
            See All Videos >>
          </Link>
        </p>
      </Container>
    )
  }

  return <></>
}

const Container = styled.div``

export default VideoBlock
