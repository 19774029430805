import * as React from "react"
import { get as _get, find as _find } from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import "./homepage.scss"
import styled from "styled-components"
import Badge from "../common/Badge"

const JukeboxBlock = () => {
  const data = useStaticQuery(
    graphql`
      query SlaightMusicJukeboxBlock {
        drupal {
          blockContentQuery(
            filter: {
              conditions: { field: "id", operator: IN, value: ["6", "7"] }
            }
          ) {
            count
            entities {
              entityId
              ... on Default_BlockContentBasic {
                body {
                  processed
                }
              }
            }
          }
        }
      }
    `
  )

  const blocks = _get(data, "drupal.blockContentQuery.entities", [])

  const blockJukebox = _get(
    _find(blocks, { entityId: "6" }),
    "body.processed",
    ""
  )

  return (
    <Container className="container text-center py-section">
      <div id="jukebox">
        <Badge color="#ffffff">Audio</Badge>
        <h2>
          The Slaight Music <span className="purple-underline">Jukebox</span>
        </h2>
        <p>Stream full length songs from our roster of artists.</p>
        <div dangerouslySetInnerHTML={{ __html: blockJukebox }} />
      </div>
    </Container>
  )
}

const Container = styled.div``

export default JukeboxBlock
