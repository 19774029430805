import * as React from "react"

import HomepageHero from "./homepageHero"
import LatestNews from "./LatestNews"
import JukeboxBlock from "./JukeboxBlock"
import LatestReleases from "./LatestReleases"
import TeamMembers from "./TeamMembers"
import VideoBlock from "./VideoBlock"
import LatestEvents from "./LatestEvents"
import "./homepage.scss"
import SMLogo from "../../images/SM_logo.svg"
import SFFLogo from "../../images/SFF_logo.svg"
import Menu from "../../components/menu/menu"
import SayHello from "../../components/common/SayHello"
import AnimationCirclePink from "../animation/animation-circle-pink"
import AnimationCircleWhite from "../animation/animation-circle-white"
import AnimationElement1 from "../animation/animation-element-1"
import AnimationElement2 from "../animation/animation-element-2"

const Homepage = () => (
  <div className="homepage-wrap">
    <div className="text-center">
      <div className="pt-4" id="top">
        <Menu />
      </div>
      <HomepageHero />
      <AnimationCircleWhite className="animation animation-circle-white-1" />
      <LatestNews />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          marginTop: "-50px",
          left: "-150px",
          width: "60px",
        }}
      />
      <LatestReleases />
      <AnimationElement2
        className="animation"
        style={{
          marginTop: "-150px",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          marginTop: "-50px",
          left: "-150px",
          width: "60px",
        }}
      />
      <LatestEvents />
      <AnimationElement2
        className="animation"
        style={{
          marginTop: "-150px",
          position: "absolute",
          right: "-150px",
          width: "120px",
        }}
      />
      <AnimationCirclePink className="animation animation-circle-pink-1" />
      <VideoBlock />
      <AnimationElement2
        className="animation"
        style={{
          marginTop: "-50px",
          position: "absolute",
          left: "-150px",
          width: "120px",
        }}
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          marginTop: "250px",
          right: "-150px",
          transform: "scaleY(-1)",
          width: "60px",
        }}
      />
      <JukeboxBlock />
      <AnimationElement2
        className="animation"
        style={{
          marginTop: "150px",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
      <TeamMembers />
      <AnimationCircleWhite className="animation animation-circle-white-2" />
      <SayHello />
      <div className="container text-center py-5 position-relative">
        <div className="row align-items-center">
          <div className="col text-start">
            <SMLogo id="slaightmusic" className="sm-logo" />
          </div>
          <div className="col text-end">
            <SFFLogo id="slaightmusicfamily" className="sm-logo" />
          </div>
          <div className="top-button mt-5 text-center">
            <a href="#top">
              <span></span>Top
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Homepage
