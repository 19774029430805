import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Badge from "../common/Badge"
import FormattedTitle from "../common/FormattedTitle"

const LatestEvents = () => {
  const data = useStaticQuery(
    graphql`
      query GetLatestEvents {
        drupal {
          nodeQuery(
            filter: {
              conditions: [
                {
                  field: "status"
                  value: "1"
                  operator: EQUAL
                },
                {
                  field: "type"
                  value: "project"
                  operator: EQUAL
                }
              ]
            }
            sort: {
              field: "field_event_date",
              direction: ASC
            }
            offset: 0
            limit: 3
          ) {
            count
            entities {
              entityId
              entityLabel
              entityUrl {
                path
              }
              ... on Default_NodeProject {
                title: entityLabel
                image: fieldSmallCircleEvent {
                  url
                  id: targetId
                }
                body {
                  value: processed
                  summary: summaryProcessed
                }
                eventDate: fieldEventDate
                thumbnail: gatsbyImageThumbnail {
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      height: 400
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  if (data?.drupal?.nodeQuery?.entities?.length) {
    const entities = data?.drupal?.nodeQuery?.entities || [];
    return (
      <>
        <BlockContainer className="container text-start py-section">
          <BlockContainerInnerWrapper>
            <Badge color="#ffffff">Events & Organizations</Badge>
            <h2>What's <span className="purple-underline">Coming Up</span></h2>
            <div>
              <p>We're proud to support many different cultural events and institutions.</p>
              <Link to={'/events'} className="read-more white">View All Events >></Link>
            </div>
            <div className="row py-5 three-items-grid">
              {entities.map((entity, index) => {
                const title = entity?.title || 'Unknown';
                const eventDate = new Date(entity?.eventDate * 1000).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
                const thumbnail = getImage(entity?.thumbnail || {});
                const entityUrl = entity?.entityUrl?.path;
                return (
                  <div key={index} className="col col-12 col-md-4 align-self-start gx-md-5">
                    <>
                      <Link to={entityUrl}>
                        <GatsbyImage image={thumbnail} alt={entity?.entityLabel} className="rounded-4 mt-4 mt-md-0" />
                      </Link>
                      <div className="pt-4">
                        <h3>
                          <Link to={entityUrl} className="white">
                            <FormattedTitle>{title}</FormattedTitle>
                          </Link>
                        </h3>
                        <div>{eventDate}</div>
                      </div>
                    </>
                  </div>
                )
              })}
            </div>
          </BlockContainerInnerWrapper>
        </BlockContainer>
      </>
    )
  }

  return <></>
}

const BlockContainer = styled.div``

const BlockContainerInnerWrapper = styled.div``

export default LatestEvents
