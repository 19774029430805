import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Badge from "../common/Badge"
import FormattedTitle from "../common/FormattedTitle"

const LatestReleases = () => {
  const data = useStaticQuery(
    graphql`
      query GetLatestReleases {
        drupal {
          nodeQuery(
            filter: {
              conditions: [
                { field: "status", value: "1", operator: EQUAL }
                { field: "type", value: "release", operator: EQUAL }
              ]
            }
            sort: { field: "field_release_date", direction: DESC }
            offset: 0
            limit: 3
          ) {
            count
            entities {
              entityId
              entityLabel
              entityUrl {
                path
              }
              ... on Default_NodeRelease {
                image: fieldImage {
                  url
                  id: targetId
                }
                body {
                  value: processed
                  summary: summaryProcessed
                }
                thumbnail: gatsbyImageThumbnail {
                  childImageSharp {
                    gatsbyImageData(width: 400, height: 400)
                  }
                }
                artist: fieldArtist {
                  entity {
                    entityId
                    entityLabel
                  }
                }
                url: fieldUrl {
                  value
                  processed
                }
                releaseDate: fieldReleaseDate {
                  value
                  date
                }
                embedCode: fieldEmbedCode
              }
            }
          }
        }
      }
    `
  )

  if (data?.drupal?.nodeQuery?.entities?.length) {
    const entities = data?.drupal?.nodeQuery?.entities || []
    return (
      <>
        <BlockContainer className="container text-start py-section">
          <BlockContainerInnerWrapper>
            <Badge color="#ffffff">New Music</Badge>
            <h2>
              Latest <span className="purple-underline">Releases</span>
            </h2>
            <div>
              <p>
                Here are the most recent releases from the artists we support.
              </p>
              <p>
                Click through to stream each track, or visit the{" "}
                <a href="#jukebox">jukebox</a>.
              </p>
              <Link to={"/releases"} className="read-more white">
                See All Releases >>
              </Link>
            </div>
            <div className="row py-5 three-items-grid">
              {entities.map((entity, index) => {
                const artistName =
                  entity?.artist?.entity?.entityLabel || "Unknown"
                const releaseDate = entity?.releaseDate?.value || "Unknown"
                const thumbnail = getImage(entity?.thumbnail || {})
                const entityUrl = entity?.entityUrl?.path
                return (
                  <div
                    key={index}
                    className="col col-12 col-md-4 align-self-start gx-md-5"
                  >
                    <>
                      <Link to={entityUrl}>
                        <GatsbyImage
                          image={thumbnail}
                          alt={entity?.entityLabel}
                          className="rounded-4 mt-4 mt-md-0"
                        />
                      </Link>
                      <div className="pt-4">
                        <h3>
                          <Link to={entityUrl} className="white">
                            <FormattedTitle>{artistName}</FormattedTitle>
                          </Link>
                        </h3>
                        <div>
                          {entity?.entityLabel},{" "}
                          <span
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            released {releaseDate}
                          </span>
                        </div>
                      </div>
                    </>
                  </div>
                )
              })}
            </div>
          </BlockContainerInnerWrapper>
        </BlockContainer>
      </>
    )
  }

  return <></>
}

const BlockContainer = styled.div``

const BlockContainerInnerWrapper = styled.div``

export default LatestReleases
