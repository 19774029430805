import * as React from "react"

import Layout from "../components/layout/layout"
import Homepage from "../components/homepage/homepage"
import { SEO as SearchEngineOptimization } from "../components/common/seo"
import { DEFAULT_SITE_DESCRIPTION } from "../lib/helper"

const IndexPage = () => (
  <Layout defaultLayout={false}>
    <Homepage />
  </Layout>
)

export default IndexPage

export const Head = () => {
  return (
    <SearchEngineOptimization
      title="Home"
      description={DEFAULT_SITE_DESCRIPTION}
      pathname="/"
    />
  )
}
