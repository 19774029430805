import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { getMachineName, mapSocialLinks } from "../../lib/helper"
import SocialLinks from "../common/SocialLinks"
import Badge from "../common/Badge"
import "./teamMembers.scss"

const TeamMembers = () => {
  const data = useStaticQuery(
    graphql`
      query GetTeamMembers {
        drupal {
          nodeQuery(
            filter: {
              conditions: [
                { field: "status", value: "1", operator: EQUAL }
                { field: "type", value: "team_member", operator: EQUAL }
                { field: "promote", value: "1", operator: EQUAL }
              ]
            }
            sort: { field: "draggable_views_weight" }
            offset: 0
            limit: 9
          ) {
            count
            entities {
              entityId
              entityLabel
              entityUrl {
                path
              }
              ... on Default_NodeTeamMember {
                image: fieldImage {
                  url
                  id: targetId
                  alt
                  title
                }
                body {
                  value: processed
                  summary: summaryProcessed
                }
                thumbnail: gatsbyImageThumbnail {
                  childImageSharp {
                    gatsbyImageData(width: 400, height: 450)
                  }
                }
                badge: fieldTitle
                socialLinks: fieldSocialLink {
                  url {
                    path
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  if (data?.drupal?.nodeQuery?.entities?.length) {
    const entities = data?.drupal?.nodeQuery?.entities || []
    return (
      <>
        <BlockContainer className="container text-center py-section">
          <BlockContainerInnerWrapper>
            <Badge color="#ffffff">Team</Badge>
            <h2 className="mb-4">
              The <span className="purple-underline">Slaight Mu</span>sic Team
            </h2>
            <div className="row justify-content-md-center justify-content-start team-grid pt-2">
              {entities.map((entity, index) => {
                const badge = entity?.badge || "Unknown"
                const thumbnail = getImage(entity?.thumbnail || {})
                const url = `/team#${getMachineName(entity?.entityLabel)}`
                const socialLinks = mapSocialLinks(entity?.socialLinks || [])
                return (
                  <div
                    key={index}
                    className={`team-item col gx-5 col-6 col-md-4 align-self-start${
                      (index + 1) % 3 === 2 ? " bigger" : " mt-4"
                    }`}
                  >
                    <Link to={url}>
                      <GatsbyImage
                        image={thumbnail}
                        alt={entity?.entityLabel}
                        className="rounded-4 mb-4"
                      />
                    </Link>
                    <div
                      className="p-md-4 pt-4 pt-md-0"
                      style={{
                        textAlign: "start",
                      }}
                    >
                      <h3>
                        <Link to={url} className="white">
                          {entity?.entityLabel}
                        </Link>
                      </h3>
                      <span>{badge}</span>
                      <div className="py-2">
                        <SocialLinks socialLinks={socialLinks}></SocialLinks>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </BlockContainerInnerWrapper>
        </BlockContainer>
      </>
    )
  }

  return <></>
}

const BlockContainer = styled.div``

const BlockContainerInnerWrapper = styled.div``

export default TeamMembers
