import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Badge from "../common/Badge"

const LatestNews = () => {
  const data = useStaticQuery(
    graphql`
      query GetLatestNews {
        drupal {
          nodeQuery(
            filter: {
              conditions: [
                { field: "status", value: "1", operator: EQUAL }
                { field: "type", value: "article", operator: EQUAL }
              ]
            }
            sort: { field: "created", direction: DESC }
            offset: 0
            limit: 1
          ) {
            count
            entities {
              entityId
              entityLabel
              entityUrl {
                path
              }
              ... on Default_NodeArticle {
                image: fieldImage {
                  url
                  id: targetId
                }
                body {
                  value: processed
                  summary: summaryProcessed
                }
                thumbnail: gatsbyImageThumbnail {
                  childImageSharp {
                    gatsbyImageData(width: 400)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  if (data?.drupal?.nodeQuery?.entities?.length) {
    const entity = data?.drupal?.nodeQuery?.entities[0]
    const thumbnail = getImage(entity?.thumbnail)
    const entityUrl = entity?.entityUrl?.path
    return (
      <div id="next">
        <CardContainer className="container text-start card">
          <Card className="p-2 p-md-4">
            <Badge>Latest News</Badge>
            <div>
              <div className="row align-items-center">
                <div className="col-sm-6 text-start">
                  <h2>{entity.entityLabel}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: entity.body.summary }}
                  />
                </div>
                {thumbnail && (
                  <div className="col-sm-6 text-center">
                    <GatsbyImage
                      alt={entity?.entityLabel}
                      image={thumbnail}
                      imgClassName="preview"
                      loading="eager"
                      className="rounded-4 my-2"
                    />
                  </div>
                )}
              </div>
            </div>
            <Link to={entityUrl} className="read-more py-2">
              Read More >>
            </Link>
          </Card>
        </CardContainer>
      </div>
    )
  }

  return <></>
}

const CardContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const Card = styled.div`
  color: #000000;
`

export default LatestNews
