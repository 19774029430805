import * as React from "react"
import { Link } from "gatsby"
import SMLogo from "../../images/SM_logo.svg"
import SFFLogo from "../../images/SFF_logo.svg"
import SlaightSoloLogo from "../../images/SLAIGHT-SOLO_logo.svg"
import RArrow from "../../images/arrow-right-thin-white.svg"
import styled from "styled-components"
import "./homepageHero.scss"

const HomepageHero = () => {
  return (
    <>
      <div className="container text-center py-4 py-md-4">
        <div className="row align-items-center">
          <div className="col">
            <SMLogo id="slaightmusic" className="sm-logo" />
          </div>
          <div className="col">
            <SFFLogo id="slaightmusicfamily" className="sm-logo" />
          </div>
        </div>
      </div>
      <div
        className="container text-center pt-5 pb-4 pt-md-5"
        style={{
          display: "grid",
        }}
      >
        <SlaightSoloLogo
          style={{
            gridArea: "1/1",
            position: "relative",
            opacity: "0.6",
          }}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="text-center">
            <h1 className="display-3">
              Supporting <span className="purple-underline">Canadian</span>{" "}
              <span className="text-nowrap">Arts & Culture</span>
            </h1>
          </div>
        </div>
        <div className="container text-end py-4 px-2 px-md-4">
          <StyledLink to={"/about"} className="white">
            Learn more about what we do{" "}
            <RArrow
              style={{
                height: "1rem",
                marginLeft: "0.25rem",
                width: "1rem",
              }}
            />
          </StyledLink>
        </div>
        <div className="mt-5 mb-5">
          <a href="#next" title="Scroll Down">
            <span className="scroll"></span>
          </a>
        </div>
      </div>
    </>
  )
}

const StyledLink = styled(Link)`
  @media (min-width: 768px) {
    padding-right: 4rem;
  }
`

export default HomepageHero
